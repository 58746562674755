import React, { useEffect, useState } from 'react';

// Components
import StepsNav from './StepsNav';
import BreakingNews from 'components/CmsComponents/FeaturedMarketing/BreakingNews';
import { formatClassName } from 'utils/formatClassName';

// Styles
import * as S from './style';

// Assets
import arrowRightWhite from 'images/icons/arrowRightWhite.svg';
import arrowRight from 'images/icons/directionalArrowRight.svg';
import arrowLeft from 'images/icons/directionalArrowLeft.svg';

function CarouselBanner({ content }) {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [bannerWidth, setBannerWidth] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const isWindow = typeof window !== 'undefined';
  const mobile = isWindow && window.innerWidth <= 430;

  useEffect(() => {
    if (isWindow) {
      setBannerWidth(window.innerWidth);
      const handleResize = () => {
        setBannerWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isWindow]);

  useEffect(() => {
    if (isAutoPlay) {
      const autoplay = setInterval(() => {
        goToNextBanner();
      }, 5000);

      return () => {
        clearInterval(autoplay);
      };
    } else {
      stopAutoPlay;
    }
  }, [currentBanner, isAutoPlay]);

  function stopAutoPlay() {
    setIsAutoPlay(false);
    goToNextBanner();
  }

  const bannersLength = content?.bannersData.length;

  const goToNextBanner = () => {
    const nextBanner = (currentBanner + 1) % bannersLength;
    setCurrentBanner(nextBanner);
  };

  const goToPreviousBanner = () => {
    const previousBanner = (currentBanner - 1 + bannersLength) % bannersLength;
    setCurrentBanner(previousBanner);
    setIsAutoPlay(false);
  };

  const handleDotClick = idx => {
    setCurrentBanner(idx);
  };

  const formattedClassName = content?.type
    ? formatClassName(content?.type)
    : '';

  return (
    <>
      {content?.breaknews && content?.activeBreaknews && (
        <S.WrapperBreakingNews
          backgroundColor={content?.bannersData[0].backgroundColor.hex}
        >
          <S.ContainerBreakingNews>
            <BreakingNews
              className={formattedClassName}
              markdownContent={content?.breaknews ?? ''}
            />
          </S.ContainerBreakingNews>
        </S.WrapperBreakingNews>
      )}

      {content?.activeBreaknews && (
        <S.ContainerBreakingNews displayBreakingNews />
      )}

      <S.Wrapper>
        <S.Container
          id="banner-container"
          currentBanner={currentBanner}
          bannerWidth={bannerWidth}
          bannersLength={bannersLength}
        >
          {content?.bannersData.map((banner, index) => {
            return (
              <S.BannerItem
                id="banner-item"
                key={index}
                backgroundColor={
                  banner.backgroundColor && banner.backgroundColor.hex
                }
              >
                {banner.title ? (
                  <S.BannerContent>
                    <S.BannerText>
                      <S.BannerTitle>{banner.title}</S.BannerTitle>
                      {banner.subtitle && (
                        <S.BannerSubtitle>{banner.subtitle}</S.BannerSubtitle>
                      )}
                      {banner.buttonText && (
                        <a
                          href={banner.buttonLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <S.BannerButton>
                            {banner.buttonText}
                            <S.ArrowImage
                              src={arrowRightWhite}
                              alt="Seta direcional para a direita"
                            />
                          </S.BannerButton>
                        </a>
                      )}
                    </S.BannerText>
                    <S.BannerImage src={banner.bannerImage.url} alt="" />
                  </S.BannerContent>
                ) : (
                  <S.BannerContent fullImage>
                    <S.BannerText>
                      <S.BannerTitle>{banner.title}</S.BannerTitle>
                      {banner.subtitle && (
                        <S.BannerSubtitle>{banner.subtitle}</S.BannerSubtitle>
                      )}
                      {banner.buttonText && (
                        <a
                          href={banner.buttonLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <S.BannerButton>
                            {banner.buttonText}
                            <S.ArrowImage
                              src={arrowRightWhite}
                              alt="Seta direcional para a direita"
                            />
                          </S.BannerButton>
                        </a>
                      )}
                    </S.BannerText>
                    {mobile ? (
                      banner.imageLink ? (
                        <a href={banner.imageLink} target="_blank">
                          <S.BannerImage
                            src={banner.bannerImageMobile.url}
                            alt=""
                            fullImage
                          />
                        </a>
                      ) : (
                        <S.BannerImage
                          src={banner.bannerImageMobile.url}
                          alt=""
                          fullImage
                        />
                      )
                    ) : banner.imageLink ? (
                      <a href={banner.imageLink} target="_blank">
                        <S.BannerImage
                          src={banner.bannerImage.url}
                          alt=""
                          fullImage
                        />
                      </a>
                    ) : (
                      <S.BannerImage
                        src={banner.bannerImage.url}
                        alt=""
                        fullImage
                      />
                    )}
                  </S.BannerContent>
                )}
              </S.BannerItem>
            );
          })}
        </S.Container>
        {content?.bannersData.length <= 1 ? (
          <>
            <S.ArrowsContainer displayBanner />
            <S.StepsContainer displayBanner />
          </>
        ) : (
          <>
            <S.ArrowsContainer>
              <S.Arrow onClick={goToPreviousBanner}>
                <img src={arrowLeft} alt="Ir para o anterior" />
              </S.Arrow>
              <S.Arrow onClick={stopAutoPlay}>
                <img src={arrowRight} alt="Ir para o próximo" />
              </S.Arrow>
            </S.ArrowsContainer>
            <S.StepsContainer>
              <StepsNav
                current={currentBanner}
                steps={bannersLength}
                handleChange={handleDotClick}
              />
            </S.StepsContainer>
          </>
        )}
      </S.Wrapper>
    </>
  );
}

export { CarouselBanner };
